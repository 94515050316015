import React from 'react'
import { ThemeContext } from '../context/ThemeContext'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { H1, Excerpt } from '../components/Text'
import Link from '../components/Link'

export default function NotFound() {
  const theme = React.useContext(ThemeContext)

  return (
    <Layout>
      <Hero>
        <H1 textColor={theme.color}>What are you looking for, buddy?</H1>
        <Excerpt textColor={theme.color}>
          Unfortunately, the page you want to access does not exist. Try again
          or go to the start page, from there you will hopefully find the right
          one.
        </Excerpt>
        <Link to="/" textColor={theme.color} variant="large">
          TO START PAGE
        </Link>
      </Hero>
    </Layout>
  )
}
